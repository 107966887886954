export const ROUTES = {
  HOME: '/',
  LIST_AVATAR: '/my-avatar',
  DETAIL_AVATAR: '/my-avatar/:id',
  DETAIL_AVATAR_WITH_STYLE: '/my-avatar/:id/:style',
  APP_PAGE: '/generate',
  // HOME_PAGE: '/home-page',
  ERROR: '*',
  SALE_PAGE: '/sales',
};
