import React from 'react';

export default function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        d="M8.22917 2.82031C8.40007 2.82031 8.56398 2.8882 8.68482 3.00905C8.80567 3.1299 8.87356 3.2938 8.87356 3.46471V8.40508H13.8139C13.9848 8.40508 14.1487 8.47297 14.2696 8.59382C14.3904 8.71467 14.4583 8.87857 14.4583 9.04948C14.4583 9.22038 14.3904 9.38429 14.2696 9.50514C14.1487 9.62598 13.9848 9.69388 13.8139 9.69388H8.87356V14.6342C8.87356 14.8052 8.80567 14.9691 8.68482 15.0899C8.56398 15.2108 8.40007 15.2786 8.22917 15.2786C8.05826 15.2786 7.89436 15.2108 7.77351 15.0899C7.65266 14.9691 7.58477 14.8052 7.58477 14.6342V9.69388H2.6444C2.47349 9.69388 2.30959 9.62598 2.18874 9.50514C2.06789 9.38429 2 9.22038 2 9.04948C2 8.87857 2.06789 8.71467 2.18874 8.59382C2.30959 8.47297 2.47349 8.40508 2.6444 8.40508H7.58477V3.46471C7.58477 3.2938 7.65266 3.1299 7.77351 3.00905C7.89436 2.8882 8.05826 2.82031 8.22917 2.82031Z"
        fill="black"
      />
    </svg>
  );
}
